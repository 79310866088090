<template>
  <view-base ref="base">
    <div class="mb-3">
      <h4>Integração UberEats</h4>
      <div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <center>
          <b>Integração solicitada com sucesso</b>
          <br>
          Em breve entraremos em contato caso necessário, Obrigado
        </center>
      </div>
    </div>
  </view-base>
</template>

<style scoped>
</style>

<script>
import Ripple from 'vue-ripple-directive'

import axios from 'axios'
import ViewBase from '@/views/ViewBase.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    ViewBase,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ordersToday: [],
      totalToday: 0,
      ordersCurrentWeek: [],
      totalCurrentWeek: 0,
      ordersLastWeek: [],
      totalLastWeek: 0,
      ordersLast15: [],
      totalLast15: 0,
      userRole: getUserData().role.code,
      storeId: getUserData().store_id,
      columns: [{
          title: 'Loja',
          name: 'storeName',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
      {
          title: 'Código',
          name: 'code',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
      {
          title: 'Cliente',
          name: 'eaterName',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
      {
          title: 'Hora',
          name: 'formatDate',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
      },
      {
          title: 'Valor',
          name: 'deliveryCost',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
      },
      {
          title: 'Cidade',
          name: 'delivered_city',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
      },
      ],
      sortColunms: [],
      filteredSize: 0,
    }
  },
  mounted() {
    const self = this
    this.$refs.base.showLoading()

    axios.defaults.headers.common.Accept = 'application/json'
    axios.defaults.headers.common.Authorization = `Bearer ${window.$cookies.get('access_token')}`
    axios.post(`${process.env.VUE_APP_CORE_ENDPOINT}/store/uber/integration/callback`, { code: this.$route.query.code + this.$route.hash })
    .then(() => {
      self.goToHomePage()
    })
    .catch(error => {
      this.$refs.base.checkError(error)
      this.$refs.base.showToastError(this.$t(error.response?.data?.error))
    })
    .finally(() => {
      this.$refs.base.hideLoading()
    })
  },
  created() {
  },
  methods: {
    goToHomePage() {
      setTimeout(() => this.$router.push({ path: '/' }), 5000)
    },
  },
}
</script>
